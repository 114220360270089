import { render, staticRenderFns } from "./RegisteredVehicleDropdown.vue?vue&type=template&id=bdbc2edc&"
import script from "./RegisteredVehicleDropdown.vue?vue&type=script&lang=js&"
export * from "./RegisteredVehicleDropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports