<template>
    <v-container fluid class="px-0">
        <v-select
            v-model="txt_passengers"
            :items="passengerOptions"
            label="Kies passagiers"
            multiple
            :item-text="item => `${item.firstname} ${item.lastName}`"
            item-value="personId"
            hide-details
            hide-selected
            hide-no-data
            return-object
            @change="onValueChanged"
            :menu-props="{ contentClass: 'registered-passenger-options', bottom: true, offsetY: true }"
            chips>
            <template v-slot:selection="{ item, index }">
                <v-chip>
                    <span>{{ `${item.firstname} ${item.lastName}` }}</span>
                    <v-icon
                        v-if="!isPreselected(item.personId)"
                        small
                        class="ml-2"
                        @click="remove(item.personId, index)">
                        $delete
                    </v-icon>
                </v-chip>
            </template>
            <template v-slot:no-data>
              <div class="no-data"></div>
            </template>
        </v-select>
    </v-container>
</template>

<script>
import MenuHelperMixin from "@/mixins/menu-helper.mixin";

export default {
    name: 'RegisteredPassengerDropdown',
    props: 
    {
        orginalPreselectedItems: Array,
        selectedPassengers: Array,
        passengerOptions: Array
    },
    beforeMount()
    {
        this.mapPassengers();
    },
    mixins: [MenuHelperMixin],
    data() {
        return {
            txt_passengers: [],
            hasChanged: false,
        }
    },
    watch: {
        selectedPassengers: {
            deep: true,
            handler() {
                this.mapPassengers();
            }
        },
    },
    methods:
    {
        isPreselected(personId)
        {
            var item = this.orginalPreselectedItems.find(x => x.personId == personId);
            return item != null;
        },
        remove(personId, index) {
            
            if(this.isPreselected(personId))
            {
                return;
            }

            this.txt_passengers.splice(index, 1);
            this.onValueChanged();
        },
        mapPassengers()
        {
            if (this.selectedPassengers == undefined)
            {
                this.txt_passengers = [];
                return;
            }

            this.txt_passengers = this.passengerOptions.filter(x => this.selectedPassengers.map(y => y.personId).includes(x.personId));
        },
        onValueChanged()
        {
            this.$emit('changed', this.txt_passengers);
        }
    }
}
</script>
<style scoped>
.registered-passenger-options .v-select-list
{
    padding: 0px;
}

.registered-passenger-options .v-select-list .no-data
{
    display: none;
}
</style>