<template>
    <div>
       <v-select 
            v-model="txt_vehicle"
            :items="vehicleOptions"
            offset-y
            :item-text="item => getVehicleChoiceText(item)"
            item-value="id"
            placeholder="Kies voertuig"
            return-object
            dense></v-select>
    </div>
</template>

<script>
import MenuHelperMixin from "@/mixins/menu-helper.mixin";

export default {
    name: 'RegisteredVehicleDropdown',
    props: 
    {
        vehicle: Object,
        vehicleOptions: Array
    },
    mixins: [MenuHelperMixin],
    beforeMount()
    {
        this.txt_vehicle = this.vehicle;
    },
    data() {
        return {
            txt_vehicle: {},
        }
    },
    watch: {
        vehicle: {
            deep: true,
            handler() {
                this.txt_vehicle = this.vehicle;
            }
        },
        txt_vehicle: 'onValueChanged'
    },
    methods:
    {
        getVehicleChoiceText(item)
        {
            let kenteken = item.licensePlate && item.licensePlate != "" ? ` (${item.licensePlate})` : "";
            return `${this.getVehicleMake(item.make)} ${item.model}${kenteken}`;
        },
        onValueChanged()
        {
            this.$emit('changed', this.txt_vehicle);
        }
    }
}
</script>