<template>
    <div class="py-0">
       <v-row no-gutters>
            <v-col cols="12" class="header-title break-word my-2">Hoi {{orderDetails.driver.firstname}}, {{ getIntro() }}</v-col>
            <v-col cols="12" class="d-inline-flex" v-if="roadtrip.packedLunchPrice != 0">
                <v-checkbox v-model="orderDetails.driver.hasLunch" class="ma-0" :disabled="isPreselectedLunch(orderDetails.driver.personId)">
                    <template v-slot:label>
                        <div>
                        Lunchpakket
                        </div>
                    </template>
                </v-checkbox>
            </v-col>
            <v-col cols="12" class="d-inline-flex" v-if="roadtrip.dinnerPrice != 0">
                <v-checkbox v-model="orderDetails.driver.hasDinner" class="ma-0" :disabled="isPreselectedDinner(orderDetails.driver.personId)">
                    <template v-slot:label>
                        <div>
                        Diner
                        </div>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                {{`Uw allergieën en voorkeuren: ${getFoodRestrictions(orderDetails.driver.allergies)}`}}
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <div class="header-title mt-4 mb-1 font-weight-medium">Passagiers</div>
                <div class="mb-2">Staat de passagier die u wilt toevoegen er niet tussen? Voeg deze dan toe op uw <span class="link" @click="goto(`/dashboard#passagiers`)"><b>dashboard</b></span>.</div>

                <RegisteredPassengerDropdown 
                    :orginalPreselectedItems="existingOrders.orderDetails"
                    :selectedPassengers="orderDetails.registeredPassengers" 
                    :passengerOptions="passengerOptions" 
                    @changed="orderDetails.registeredPassengers = $event" />
                
            </v-col>
            <v-col cols="12">
                <template v-for="(item, i) in orderDetails.registeredPassengers">
                    <div :key="i">
                        <v-list-item class="px-0 pt-1 list-item-avatar">
                            <v-list-item-avatar width="60" height="60">
                                <div class="rounded-circle d-inline-block initials-avatar text-uppercase font-weight-black">{{item.initials}}
                                </div>
                            </v-list-item-avatar>
                        
                            <v-list-item-content class="pl-3">
                                <v-list-item-title v-text="`${item.firstname} ${item.lastName}`" class="subtitle-1"></v-list-item-title>
                                <div class="d-inline-flex" v-if="roadtrip.packedLunchPrice != 0">
                                    <v-checkbox v-model="item.hasLunch" class="ma-0" height="30" :disabled="isPreselectedLunch(item.personId)" :hide-details="true">
                                        <template v-slot:label>
                                            <div>
                                            Lunchpakket
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </div>
                                <div class="d-inline-flex" v-if="roadtrip.dinnerPrice != 0">
                                     <v-checkbox v-model="item.hasDinner" class="ma-0" height="30" :disabled="isPreselectedDinner(item.personId)" :hide-details="true">
                                        <template v-slot:label>
                                            <div>
                                            Diner
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                        <div class="pb-3">
                            {{`Allergieën en voorkeuren: ${getFoodRestrictions(item.foodRestrictions)}`}} 
                        </div>
                        <v-divider color="white"></v-divider>
                    </div>
                </template>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <p class="header-title mt-4 mb-1 font-weight-medium">Voertuig</p>
                <div class="mb-5">Staat het voertuig dat u wilt toevoegen er niet tussen? Voeg deze dan toe op uw <span class="link" @click="goto(`/dashboard#voertuigen`)"><b>dashboard</b></span>.</div>

                <RegisteredVehicleDropdown :vehicle="orderDetails.car" 
                    :vehicleOptions="vehicleOptions"
                    @changed="orderDetails.car = $event" />
                
            </v-col>
        </v-row>

        <!-- Extra information -->
        <OrderNotes :orderDetails="orderDetails" />

        <!-- Voucher -->
        <Voucher 
            :accountId="userId"
            :roadtrip="roadtrip" 
            :orderDetails="orderDetails" 
            :category="getCategory()"/>

        <v-btn color="primary" block
            @click="order">
            {{ orderDetails.originalAmountPending == 0 ? `Wijzigen` : `Betalen` }}
        </v-btn>
    </div>
</template>

<script>
module.exports = {
    props: {
        roadtrip:Object,
        order: Function,
        orderDetails: Object,
        passengerOptions: Array,
        vehicleOptions: Array,
        getFoodRestrictions: Function,
        goto: Function,
        existingOrders: Object,
        userId: String
    },   
    beforeCreate: function () {
        this.$options.components.OrderNotes = require('@/components/roadtrip/signup/OrderNotes').default,
        this.$options.components.Voucher = require('@/components/roadtrip/signup/Voucher').default,
        this.$options.components.RegisteredVehicleDropdown = require('@/components/common/options/RegisteredVehicleDropdown').default,
        this.$options.components.RegisteredPassengerDropdown = require('@/components/common/options/RegisteredPassengerDropdown').default
    }, 
    data: () => ({
        editIcon: require('@/assets/icons/edit.svg'),
    }),
    methods:
    {
        getCategory()
        {
            return this.existingOrders.hasMainOrder 
                ? `RoadtripModificatie` // wijzigen
                : `Roadtrip`; // aanmelden
        },
        getIntro()
        {
            if(this.existingOrders.hasMainOrder)
            {
                // wijzigen
                return `op deze pagina kunt u uw gewenste wijzigingen doorgeven.`;
            }

            // aanmelden
            let intro = "";
            if (this.roadtrip.packedLunchPrice != 0 && this.roadtrip.dinnerPrice == 0){
                intro = `wilt u een lunchpakket?`
            }
            else if (this.roadtrip.packedLunchPrice == 0 && this.roadtrip.dinnerPrice != 0){
                intro = `wilt u dineren?`
            }
            else if (this.roadtrip.packedLunchPrice != 0 && this.roadtrip.dinnerPrice != 0){
                intro = `wilt u een lunchpakket en dineren?`
            }
            else
            {
                intro = `geef hieronder uw gewenste ordergegevens door.`;
            }

            return intro;
        },
        isPreselectedLunch(personId)
        {
            var item = this.existingOrders.orderDetails.find(x => x.personId == personId);
            if(item != null)
            {
                return item.hasPackedLunch;
            }

            return false;
        },
        isPreselectedDinner(personId)
        {
            var item = this.existingOrders.orderDetails.find(x => x.personId == personId);
            if(item != null)
            {
                return item.hasDinner;
            }

            return false;
        }
    }
}
</script>
<style>
.header
{
    display: inline-flex;
}

.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.list-item-avatar
{
    align-items: baseline;
}

.list-item-avatar .v-avatar
{
    display: contents;
}

.list-item-avatar hr.v-divider
{
    display: none;
}

.initials-avatar
{
    background: white;
    color: #181717;
    font-size: 22px;
    padding: 10px 14px;
}

.v-input--checkbox .v-input__slot
{
    margin: 0px;
}
</style>